<template>
  <div>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title> Create Order </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="secondary"
          :disabled="!order.orderCustomerId || !order.portOfLoadValue || !order.portOfDischargeValue || !order.finalDestinationValue || (order.orderStatus != 'Planned' && (!order.vessel)) || (order.orderStatus == 'Planned' && (!order.plannedWeek || !order.plannedYear)) || (order.orderStatus != 'Planned' && (!order.etd)) || order.bookingContainerItems.length == 0"
          @click="saveOrder()"
          :loading="savingOrder"
          ><v-icon>save</v-icon></v-btn
        >
        <v-btn small plain icon @click="$emit('close')"><v-icon color="redPop">close</v-icon></v-btn>
      </v-toolbar>
        <v-row no-gutters class="px-2 pb-2">
          <v-col cols="12" sm="4" class="pr-1" style="height: auto">
            <v-card style=" border-radius: 20px; background-color: var(--v-toolbar-lighten1) !important; height: 100%" outlined >
                <v-list dense class="text-center">
                  <v-subheader style="font-size: 16px">
                <v-icon color="grey" class="mr-2">handshake</v-icon>
                Deal Terms
              </v-subheader>
              <v-divider></v-divider>
              <v-row class="my-2" no-gutters >
                <v-col cols="12" sm="6" class="ma-0 pa-0">
                  <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" >
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" class="text-left">
                        <v-list-item-action>
                          <v-icon small color="grey"> tag </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="orderFinancialSetting.incoTerm">
                            {{ orderFinancialSetting.incoTerm }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle> INCO Term </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                <v-list dense>
                  <v-list-item class="text-left"
                    v-for="incoterm in incoTerms"
                    :key="incoterm.id"
                    @click="
                      orderFinancialSetting.incoTerm = incoterm.abbreviation;
                    "
                  >
                    <v-list-item-title>{{
                      incoterm.abbreviation
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="ma-0 pa-0">

              <v-list-item @click="currencyModal = true" class="text-left">
                <v-list-item-action>
                  <v-icon small color="grey"> currency_exchange </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="orderFinancialSetting.baseCurrency">
                    {{ orderFinancialSetting.baseCurrency }}
                    <span class="ml-2">
                      {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle> Base Currency </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
                  </v-col>
                  <v-col cols="12" class="ma-0 pa-0" >
                    <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on" class="text-left">
                    <v-list-item-action>
                      <v-icon small color="grey"> trending_up </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="orderFinancialSetting.type == 'FIXED'"
                        >Fixed Pricing</v-list-item-title
                      >
                      <v-list-item-title
                        v-else-if="orderFinancialSetting.type == 'MGP'"
                        >Consignment: Minimum Guaranteed
                        Price</v-list-item-title
                      >
                      <v-list-item-title
                        v-else-if="
                          orderFinancialSetting.type == 'FULL_CONSIGNMENT'
                        "
                        >Full Consignment</v-list-item-title
                      >
                      <v-list-item-subtitle>Price Terms</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small>expand_more</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-list dense>
                  <v-list-item class="text-left"
                    @click="
                      orderFinancialSetting.type = 'FIXED';
                    "
                  >
                    <v-list-item-title>Fixed Pricing</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="text-left"
                    @click="
                      orderFinancialSetting.type = 'MGP';
                    "
                  >
                    <v-list-item-title
                      >Consignment: Minimum Guaranteed Price</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item class="text-left"
                    @click="
                      orderFinancialSetting.type = 'FULL_CONSIGNMENT';
                    "
                  >
                    <v-list-item-title>Full Consignment</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
                  </v-col>
                </v-row>
              <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">settings</v-icon>Settings
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list dense class="text-left">
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >Status</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small :color="statusColor(order.orderStatus)">
                              <v-icon small left color="white">{{ statusIcon(order.orderStatus) }}</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ order.orderStatus }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="order.orderStatus = status.name" v-for="(status, index) in orderStatus" :key="index">
                              <v-list-item-action>
                                <v-icon :color="status.color">{{ status.icon }}</v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{ status.name }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>import_export</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Movement Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <!-- <v-chip
                          small
                          @click="changeMovementType()"
                          :color="
                            order.movementType == 'EXPORT'
                              ? 'deep-orange'
                              : 'blue darken-2'
                          "
                        >
                          <v-icon small left color="white">import_export</v-icon>
                          <span style="text-transform: none; color: white">
                            {{ $Format.capitalizeFirstLetter(order.movementType) }}
                          </span>
                        </v-chip> -->

                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small  :color="
                            order.movementType == 'EXPORT'
                              ? 'deep-orange'
                              : order.movementType == 'IMPORT'? 'blue darken-2': 'grey darken-3'
                          ">
                              <v-icon small left color="white">import_export</v-icon>
                          <span style="text-transform: none; color: white">
                            {{ $Format.capitalizeFirstLetter(order.movementType) }}
                            </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="order.movementType = 'EXPORT'">
                              <v-list-item-content>
                                <v-list-item-title>Export</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="order.movementType = 'IMPORT'">
                              <v-list-item-content>
                                <v-list-item-title>Import</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="order.movementType = 'LOCAL'">
                              <v-list-item-content>
                                <v-list-item-title>Local</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>airline_stops</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >Cargo Service Type</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="indigo">
                              <span style="text-transform: none; color: white">
                                {{ order.serviceType }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="order.serviceType = 'FCL'">
                              <v-list-item-content>
                                <v-list-item-title>FCL</v-list-item-title>
                                <v-list-item-subtitle
                                  >Full Container Load</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="order.serviceType = 'LCL'">
                              <v-list-item-content>
                                <v-list-item-title>LCL</v-list-item-title>
                                <v-list-item-subtitle
                                  >Less than Container
                                  Load</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>

                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>mode_of_travel</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Mode of Transport</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="blue-grey darken-2">
                              <v-icon
                                small
                                left color="white"
                                v-if="order.modeOfTransport == 'OCEAN'"
                                >directions_boat</v-icon
                              >
                              <v-icon
                                small
                                left color="white"
                                v-else-if="order.modeOfTransport == 'AIR'"
                                >flight</v-icon
                              >
                              <v-icon
                                small
                                left color="white"
                                v-else-if="order.modeOfTransport == 'RAIL'"
                                >train</v-icon
                              >
                              <v-icon
                                small
                                left color="white"
                                v-else-if="order.modeOfTransport == 'ROAD'"
                                >local_shipping</v-icon
                              >

                              <span style="text-transform: none; color: white">
                                {{
                                  $Format.capitalizeFirstLetter(order.modeOfTransport)
                                }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item
                              @click="order.modeOfTransport = 'OCEAN'"
                            >
                              <v-list-item-content>
                                <v-list-item-title>Ocean</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="order.modeOfTransport = 'AIR'">
                              <v-list-item-content>
                                <v-list-item-title>Air</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="order.modeOfTransport = 'ROAD'"
                            >
                              <v-list-item-content>
                                <v-list-item-title>Road</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="order.modeOfTransport = 'RAIL'"
                            >
                              <v-list-item-content>
                                <v-list-item-title>Rail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">group</v-icon>Parties
                  </v-subheader>
                  <v-divider></v-divider>
                    <v-list dense class="text-left">
                      <v-list-item v-if="order.orderCustomer">
                        <v-list-item-action>
                          <v-avatar v-if="order.orderCustomer.logo" color="white">
                            <v-img :src="order.orderCustomer.logo" contain></v-img>
                          </v-avatar>
                          <v-avatar v-else color="secondary">
                            <h2>{{ order.orderCustomer.name.charAt(0) }}</h2>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ order.orderCustomer.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Selected Customer
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row justify="center">
                            <v-btn
                              small
                              @click="customerSearchModal = true"
                              icon
                              ><v-icon small>edit</v-icon></v-btn
                            >
                            <v-btn
                              small
                              @click="order.orderCustomer = undefined"
                              color="red"
                              icon
                              ><v-icon small>delete</v-icon></v-btn
                            >
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-else @click="customerSearchModal = true">
                        <v-list-item-action>
                          <v-icon color="primary">add_circle_outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Add Customer
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <i>Required</i>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="order.orderCustomer" :key="profileKey">
                        <v-list-item-action>
                          <v-icon color="secondary">handshake</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="order.consigneeProfile">
                            {{ order.consigneeProfile.systemReference }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle>
                            Shipment Profile
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row justify="center" v-if="order.consigneeProfile">
                            <v-btn
                              small
                              @click="shipmentProfileDialog = true"
                              icon
                              ><v-icon small>edit</v-icon></v-btn
                            >
                            <v-btn
                              small
                              @click="removeProfile()"
                              color="red"
                              icon
                              ><v-icon small>delete</v-icon></v-btn
                            >
                          </v-row>
                          <v-btn
                            icon
                            color="primary"
                            v-else
                            @click="shipmentProfileDialog = true"
                          >
                            <v-icon>add_circle_outline</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <!-- <v-list-item
                     
                      
                    >
                      <v-list-item-action>
                        <v-icon color="primary">add_circle_outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <span style="font-size: 16px"
                          >Add Shipment Profile</span
                        >
                      </v-list-item-content>
                    </v-list-item> -->
                      <v-list-item
                        v-if="order.consigneeProfile"
                        @click="shipmentProfileConfig = true"
                      >
                        <v-list-item-action>
                          <v-icon v-if="order.dealTerm" color="secondary"
                            >settings</v-icon
                          >
                          <v-icon v-else color="primary"
                            >add_circle_outline</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content v-if="!order.dealTerm">
                          <span style="font-size: 16px"
                            >Select Profile Configuration</span
                          >
                        </v-list-item-content>
                        <v-list-item-content v-else>
                          <v-list-item-title>
                            {{ order.dealTerm.incoTerm }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Profile Configuration
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="incoTermModal = true"
                        v-if="!order.consigneeProfile"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="!order.incoTerm ? 'secondary' : 'success'"
                            >swap_horiz</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="order.incoTerm">
                            {{ order.incoTerm }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle>
                            Inco Term
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <div v-if="order.orderCustomer">
                        <v-list-item
                          :disabled="
                            order.consigneeProfile != undefined ||
                            order.consigneeProfile != null
                          "
                          @click="(partyModal = true), (partyType = 'Shipper')"
                        >
                          <v-list-item-action>
                            <v-icon class="mt-0 pt-0" color="grey"
                              >directions_boat_filled</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.shipper">
                              {{ order.shipper.name }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle v-if="order.onBehalfShipper">
                              On behalf of {{ order.onBehalfShipper.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon x-small color="grey" class="mr-1"
                                >label</v-icon
                              >
                              Shipper
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          :disabled="
                            order.consigneeProfile != undefined ||
                            order.consigneeProfile != null
                          "
                          @click="
                            (partyModal = true), (partyType = 'Forwarder')
                          "
                        >
                          <v-list-item-action>
                            <v-icon class="mt-0 pt-0" color="grey"
                              >fast_forward</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.forwarder">
                              {{ order.forwarder.name }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle
                              v-if="order.onBehalfForwarder"
                            >
                              On behalf of {{ order.onBehalfForwarder.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon x-small color="grey" class="mr-1"
                                >label</v-icon
                              >
                              Forwarder
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                          :disabled="
                            order.consigneeProfile != undefined ||
                            order.consigneeProfile != null
                          "
                          @click="
                            (partyModal = true), (partyType = 'Consignee')
                          "
                        >
                          <v-list-item-action>
                            <v-icon class="mt-0 pt-0" color="grey"
                              >call_received</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.consignee">
                              {{ order.consignee.name }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle
                              v-if="order.onBehalfConsignee"
                            >
                              On behalf of {{ order.onBehalfConsignee.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon x-small color="grey" class="mr-1"
                                >label</v-icon
                              >
                              Consignee
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          :disabled="
                            order.consigneeProfile != undefined ||
                            order.consigneeProfile != null
                          "
                          @click="(partyModal = true), (partyType = 'Buyer')"
                        >
                          <v-list-item-action>
                            <v-icon class="mt-0 pt-0" color="grey"
                              >shopping_cart</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.buyer">
                              {{ order.buyer.name }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle v-if="order.onBehalfBuyer">
                              On behalf of {{ order.onBehalfBuyer.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon x-small color="grey" class="mr-1"
                                >label</v-icon
                              >
                              Buyer
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-list>
                </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" class="pl-1 pr-1" style="height: auto">
            <v-card style=" border-radius: 20px; background-color: var(--v-toolbar-lighten1) !important;height: 100% " outlined >
                <v-list dense>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">location_on</v-icon>
                    Origin & Destination</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    @click="(countryType = 'Origin'), (countryModal = true)"
                    :disabled="
                      order.consigneeProfile != undefined &&
                      order.consigneeProfile != null
                    "
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="!order.originCountry ? 'secondary' : 'success'"
                        >public</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.originCountry">
                        {{ order.originCountry.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Origin Country
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          contain
                          v-if="order.originCountry"
                          :src="`https://cdn.loglive.io/flags/4x3/${order.originCountry.iso2.toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="polModal = true">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          !order.portOfLoadValue ? 'secondary' : 'success'
                        "
                        >anchor</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.portOfLoadCity">
                        {{ order.portOfLoadCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Port of Load <i v-if="!order.portOfLoadValue">(Required)</i>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip v-if="order.portOfLoadValue" pill small outlined>
                        <v-avatar size="24" left>
                          <v-img
                            contain
                            v-if="order.portOfLoadValue"
                            :src="`https://cdn.loglive.io/flags/4x3/${order.portOfLoadValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ order.portOfLoadValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="podModal = true">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          !order.portOfDischargeValue ? 'secondary' : 'success'
                        "
                        >anchor</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.portOfDischargeCity">
                        {{ order.portOfDischargeCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Port of Discharge <i v-if="!order.portOfDischargeValue">(Required)</i>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip
                        v-if="order.portOfDischargeValue"
                        pill
                        small
                        outlined
                      >
                        <v-avatar size="24" left>
                          <v-img
                            contain
                            v-if="order.portOfDischargeValue"
                            :src="`https://cdn.loglive.io/flags/4x3/${order.portOfDischargeValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ order.portOfDischargeValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="fdModal = true">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          !order.finalDestinationValue ? 'secondary' : 'success'
                        "
                        >location_on</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.finalDestinationCity">
                        {{ order.finalDestinationCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Final Destination <i v-if="!order.finalDestinationValue">(Required)</i>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip
                        v-if="order.finalDestinationValue"
                        pill
                        small
                        outlined
                      >
                        <v-avatar size="24" left>
                          <v-img
                            contain
                            v-if="order.finalDestinationValue"
                            :src="`https://cdn.loglive.io/flags/4x3/${order.finalDestinationValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ order.finalDestinationValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    @click="
                      (countryType = 'Destination'), (countryModal = true)
                    "
                    :disabled="
                      order.consigneeProfile != undefined &&
                      order.consigneeProfile != null
                    "
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="
                          !order.destinationCountry ? 'secondary' : 'success'
                        "
                        >public</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.destinationCountry">
                        {{ order.destinationCountry.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Destination Country
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          contain
                          v-if="order.destinationCountry"
                          :src="`https://cdn.loglive.io/flags/4x3/${order.destinationCountry.iso2.toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                  <div v-if="order.movementType != 'LOCAL'">
                    <v-divider></v-divider>
                  <v-subheader style="font-size: 16px"
                    ><v-icon class="mr-2" color="grey">mode_of_travel</v-icon
                    >Transport Details</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="order.modeOfTransport == 'OCEAN'"
                    @click="openSailingSchedules()"
                  >
                    <v-list-item-action v-if="!order.shippingLine">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="white"
                        size="36"
                        v-if="order.shippingLine"
                      >
                        <v-img
                          v-if="order.shippingLine && order.shippingLine.logo"
                          :src="order.shippingLine.logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="order.vessel">
                      <v-list-item-title>
                        {{ order.vessel.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <u
                          style="cursor: pointer"
                          @click="voyageNumberModal = true"
                          v-if="order.mainCarriageConveyanceNumber"
                          >Voyage No:
                          {{ order.mainCarriageConveyanceNumber }}</u
                        >
                        <u
                          style="cursor: pointer"
                          @click="voyageNumberModal = true"
                          v-else
                          >No Voyage No</u
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-content>
                        <v-list-item-title>Add Vessel</v-list-item-title>
                        <v-list-item-subtitle v-if="order.orderStatus != 'Planned'"
                          ><i>(Required)</i></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="shippingLineModal = true"
                    v-if="order.modeOfTransport == 'OCEAN'"
                  >
                    <v-list-item-action v-if="!order.shippingLine">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="white"
                        size="36"
                        v-if="order.shippingLine"
                      >
                        <v-img
                          v-if="order.shippingLine && order.shippingLine.logo"
                          :src="order.shippingLine.logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.shippingLine">{{
                        order.shippingLine.name
                      }}</v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item
                    v-if="order.vessel"
                    @click="editContractNumber()"
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="order.contractNumber ? 'success' : 'secondary'"
                        >description</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.contractNumber">
                        {{ order.contractNumber }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        No Contract Selected
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="order.contractOwner">
                        <v-icon class="mr-2" small color="grey">groups</v-icon>
                        {{ getContractParty(order.contractOwner) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Selected Contract
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item> -->

                 
                  </div>
                  <div v-if="order.orderStatus != 'Planned'">
                    <v-menu
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-action>
                            <v-icon :color="order.etd ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.etd">
                              {{ formatDate(order.etd) }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Estimated Departure <i>(Required)</i>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-date-picker v-model="order.etd" no-title scrollable>
                      </v-date-picker>
                    </v-menu>
                    <!-- <v-col cols="12" sm="12"> -->
                    <v-menu
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-action>
                            <v-icon :color="order.eta ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.eta">
                              {{ formatDate(order.eta) }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Estimated Arrival
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-date-picker v-model="order.eta" no-title scrollable>
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <div v-else>
                    <v-list-item>
                          <v-list-item-action>
                            <v-icon :color="order.eta ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.plannedWeek">
                              Week {{ order.plannedWeek }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Planned Week
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-container>
                              <v-row justify="center">
                                <v-autocomplete :items="weekNumbers" v-model="order.plannedWeek" hide-details outlined dense></v-autocomplete>
                              </v-row>
                            </v-container>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action>
                            <v-icon :color="order.eta ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.plannedYear">
                              Week {{ order.plannedYear }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Planned Year
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-container>
                              <v-row justify="center">
                                <v-autocomplete :items="[(new Date().getFullYear()), (new Date().getFullYear()+1)]" v-model="order.plannedYear" hide-details outlined dense></v-autocomplete>
                              </v-row>
                            </v-container>
                          </v-list-item-action>
                        </v-list-item>
                   
                  </div>
                </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" class="pl-1" style="height: auto">
            <v-card style=" border-radius: 20px; background-color: var(--v-toolbar-lighten1) !important; height: 100% " outlined >
                <v-list dense class="text-center">
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">list_alt</v-icon>Order Details
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-row no-gutters>
                      <v-col cols="12" md="6" class="pb-4 pt-2 pl-2 pr-1">
                        <v-text-field
                          dense
                          outlined
                          hide-details
                          style="height: 40px"
                          label="Carrier Ref No"
                          persistent-placeholder
                          v-model="order.carrierReferenceNumber"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-4 pt-2 pl-1 pr-2">
                        <v-text-field
                          dense
                          outlined
                          hide-details
                          persistent-placeholder
                          style="height: 40px"
                          label="Shipper Ref No"
                          v-model="order.shipperReferenceNo"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-2 pl-2 pr-1">
                        <v-text-field
                          dense
                          persistent-placeholder
                          outlined
                          style="height: 40px"
                          hide-details
                          label="Consignee Ref No"
                          v-model="order.consigneeReferenceNo"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-subheader>Regime Code</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-if="order.regime"  @click="getRegimeCodes(true)">
                        <v-list-item-action class="mr-0 pr-0">
                          <v-btn text rounded
                              > {{
                                    order.regime.code
                                  }}</v-btn>
                                  <!-- <br> -->
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-subtitle>
                            <v-icon color="#007d5c" small class="mr-2"
                                  >thermostat</v-icon
                                > Set Temp: {{`${order.regime.setPointTemp}°C`}}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle  v-if="order.regime.protocol">
                            <v-icon color="#9d904a" small class="mr-2"
                                  >thermostat</v-icon
                                > Protocol: {{`${order.regime.protocol}°C`}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-else @click="getRegimeCodes(true)">
                        <v-list-item-content class="text-center">
                          <v-list-item-title style="color: grey">
                            No Regime Code
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                  </v-list>
                <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">shopping_cart</v-icon
                    >Order Items <v-spacer></v-spacer>
                    <v-btn icon color="primary" @click="addOrderItem()"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list dense class="text-left">
                    <v-list-item v-if="order.bookingContainerItems.length == 0">
                      <v-list-item-content>
                        <span style="font-size: 12px; color: grey"
                          >No items added.</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-for="container in order.bookingContainerItems"
                      :key="container.tempId"
                      @click="addOrderItem(container)"
                    >
                      <v-list-item-action>
                        <v-chip
                          >{{ container.quantity }} x
                          {{ container.containerTypeCode }}</v-chip
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list dense class="my-0 py-0">
                          <v-list-item
                            v-for="item in container.orderCargoItems"
                            :key="item.tempId"
                          >
                            <v-list-item-action>
                              {{ item.quantity }} {{ formatBaseUnit(item.quantityType)}}(s)
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-chip small
                                  ><v-icon small left>category</v-icon>
                                  {{
                                    $Format.capitalizeFirstLetter(item.product.name)
                                  }}
                                  <span v-if="item.varietyProduct"
                                    >:
                                    {{
                                      $Format.capitalizeFirstLetter(
                                        item.varietyProduct.name
                                      )
                                    }}</span
                                  ></v-chip
                                >
                              </v-list-item-title>
                              <v-list-item-subtitle
                                style="font-size: 11px"
                                class="text-wrap"
                              >
                                {{ productSpec(item) }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list>
            </v-card>
          </v-col>
        </v-row>
    </v-card>

    <v-dialog
      v-model="customerSearchModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingRelated">
        <v-card-title>
          Search Customer
          <v-spacer></v-spacer>
          <v-btn text @click="customerSearchModal = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prepend-inner-icon="search"
            v-model="searchCustomer"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item
              v-for="organisation in filterOrganisations"
              :key="organisation.id"
              style="height: 50px"
              @click="setCustomer(organisation)"
            >
              <v-list-item-action>
                <v-avatar
                  size="40"
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar size="40" v-else color="secondary">
                  <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="orderItemModal"
      persistent
      width="95vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Manage Order Item </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon color="secondary" @click="saveOrderItem()"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn
            v-if="orderItem.exists"
            icon
            color="red"
            @click="removeOrderItem()"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-btn text @click="(orderItemModal = false), (orderItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-list dense>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-row align="center">
                    <v-col cols="12" sm="6">

                    <v-autocomplete
                    class="my-0 py-0"
                    hide-details
                    outlined
                    dense
                    label="Container Type"
                    v-model="orderItem.containerTypeCode"
                    :items="containerTypes"
                    item-text="shortDescription"
                    item-value="typeCode"
                  >
                  </v-autocomplete>
                  </v-col>
                    <v-col cols="12" sm="3" class="text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <el-input-number
                            v-on="on"
                            class="my-0 py-0"
                            :min="1"
                            :value="orderItem.quantity"
                            size="mini"
                            v-model="orderItem.quantity"
                          ></el-input-number>
                        </template>
                        <span>Number of containers</span>
                      </v-tooltip>
                      <!-- <v-autocomplete
                          label="Base Unit"
                          v-model="orderItem.baseUnit"
                          :items="productQuantityTypes"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-autocomplete> -->
                        <!-- <el-input-number
                        class="my-0 py-0"
                        :min="1"
                        :value="orderItem.quantity"
                        size="mini"
                        v-model="orderItem.quantity"
                      ></el-input-number> -->
                    </v-col>
                  </v-row>
                 
                  <!-- <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Quantity</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                     
                    </v-list-item-action>
                  </v-list-item> -->
                </v-col>
                <v-col cols="12" sm="3" md="2" class="ma-0 pa-0">
                  <v-list-item>
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>widgets</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Containerised</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="orderItem.containerised"
                        hide-details
                        color="blue"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>widgets</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Palletised</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="orderItem.palletised"
                        hide-details
                        color="blue"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
                <!-- <v-col cols="12" sm="3" md="2">
                  <v-spacer></v-spacer>
                </v-col> -->
                <v-col cols="12" sm="4" md="3" class="ma-0 pa-0">
                  <v-list-item v-if="orderItem.containerised && orderItem.palletised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >Pallets per Container</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <el-input-number
                          v-on="on"
                            :min="0"
                            :value="orderItem.palletPerContainer"
                            size="mini"
                            v-model="orderItem.palletPerContainer"
                          ></el-input-number>
                        </template>
                        <span>Pallets per Container</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="!orderItem.containerised && orderItem.palletised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                          <v-list-item-title>Unit(s) per
                            Pallet</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <el-input-number
                            v-on="on"
                            :min="0"
                            :value="orderItem.unitPerPallet"
                            size="mini"
                            v-model="orderItem.unitPerPallet"
                          ></el-input-number>
                        </template>
                        <span>Unit(s) per Pallet</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="orderItem.containerised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Unit(s) per
                        Container</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <el-input-number
                            v-on="on" 
                            :min="0"
                            :value="orderItem.unitPerContainer"
                            size="mini"
                            v-model="orderItem.unitPerContainer"
                          ></el-input-number>
                        </template>
                        <span>Unit(s) per
                        Container</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-row>
                    <v-col cols="12" sm="12" md="8" class="pb-1 pt-0 pr-1 mb-0" align="center" justify="center">
                      <v-list-item>
                        <v-list-item-action class="mx-0 px-0 mr-1">
                          <v-icon color="grey" small>currency_exchange</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Factored Rate</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            v-model="createFactoredRate"
                            hide-details
                            color="blue"
                          ></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="4" class="pb-1 pr-1 pl-0">
                      <v-select
                      dense
                      hide-details
                      outlined
                      disabled
                      label="Base Currency"
                      v-model="orderFinancialSetting.baseCurrency">
                      </v-select>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="4" class="pb-1 pl-0 pt-1">
                      <v-select
                      dense
                      hide-details
                      outlined
                      disabled
                      label="Base Currency"
                      :items="currencies"
                      :value="orderFinancialSetting.baseCurrency">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" class="pt-0 pr-1 pb-3" v-if="createFactoredRate">
                      <v-autocomplete
                      dense
                      hide-details
                      outlined
                      label="Factored currency"
                      :items="currencies"
                      :value="orderFinancialSetting.baseCurrency"
                      v-model="factoredCurrency">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="pt-0 pr-1 pb-3 pl-0" v-if="createFactoredRate">
                      <v-text-field
                      :prefix="getSymbol(factoredCurrency)"
                      v-model.number="factoredRate"
                      hide-details
                      dense
                      outlined
                      label="Fact. rate">
                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click="applyCurrentRate">arrow_back</v-icon>
                          </template>
                          <span>Apply current rate</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:prepend-inner>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon 
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!validFactoredRate"
                              @click="applyFactoredRate = !applyFactoredRate">
                                playlist_add</v-icon>
                            </template>
                            <span v-if="!applyFactoredRate">Add to Line Items</span>
                            <span v-if="applyFactoredRate">Close add to Line Items</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="pt-0 pl-0 pb-3" v-if="createFactoredRate">
                      <v-text-field
                      disabled
                      :prefix="getSymbol(factoredCurrency)"
                      hide-details
                      dense
                      outlined
                      label="current rate"
                      v-model="currentExchangeRate"
                      :loading="loadingCurrency"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div style="width: 100%">
            <OrderItemDetail :orderItem="orderItem" :orderFinancialSetting="orderFinancialSetting" :tradedProducts="tradedProducts" :related="organisations" :tradedVariations="tradedVariations" :products="products" :orderSettings="orderSettings" :factoredRate="factoredRate" :applyFactoredRate="applyFactoredRate" :factoredRateCurrency="factoredCurrency" @closeApplyingInterface="closeApply"/>
        
          </div>
              <!-- <v-list dense subheader>
                <v-subheader style="font-size: 16px">
                  Product Specification(s) <v-spacer></v-spacer>
                </v-subheader>
              <v-data-table
                dense
                hide-default-footer
                disable-pagination
                :headers="
                  orderItem.palletised
                    ? cargoHeaders
                    : cargoHeaders.filter((x) => x.value != 'palletType')
                "
                :items="orderItem.orderCargoItems"
                style="background-color: transparent"
              >
                <template v-slot:[`header.quantity`]="{ header }">
                  {{ header.text }}
                </template>
                <template v-slot:[`item.actions`]="{ item, index }">
                  <v-container>
                    <v-row justify="center">
                      <v-btn icon small @click="duplicateItem(item)">
                        <v-icon small>content_copy</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="red"
                        small
                        @click="deleteItem(index, item)"
                      >
                        <v-icon small>cancel</v-icon>
                      </v-btn>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:[`item.quantityType`]="{ item }">
                  <v-autocomplete
                    style="font-size: 12px; border: none"
                    outlined
                    dense
                    hide-details
                    :items="productQuantityTypes"
                    item-text="text"
                    item-value="value"
                    v-model="item.quantityType"
                    @change="saveLineItem(item)"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.quantity`]="{ item }">
                  <v-text-field
                    width="120px"
                    class="ml-1 remove-padding"
                    dense
                    outlined
                    hide-details
                    v-model="item.quantity"
                    @change="saveLineItem(item)"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.product`]="{ item }">
                  <v-autocomplete
                    style="font-size: 12px; border: none"
                    outlined
                    dense
                    hide-details
                    :items="tradedProducts"
                    item-text="product.name"
                    item-value="product.id"
                    v-model="item.productId"
                    clearable
                    @change="setProduct($event, item)"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.varietyProduct`]="{ item }">
                  <v-autocomplete :key="productKey"
                    style="font-size: 12px; border: none"
                    :disabled="!item.productId"
                    outlined
                    dense
                    hide-details
                    :items="filteredValues('variety', item)"
                    item-text="name"
                    item-value="id"
                    v-model="item.varietyProduct"
                    clearable
                    return-object
                    @change="setVariety($event, item)"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.packCode`]="{ item }">
                  <v-autocomplete
                    min-width="120px"
                    class="ml-1"
                    :disabled="!item.productId"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('packCode', item)"
                    item-text="value"
                    item-value="value"
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.packCode"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.countSize`]="{ item }">
                  <v-autocomplete
                    width="120px"
                    :disabled="!item.productId"
                    class="ml-1 remove-padding"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('countSize', item)"
                    item-text="value"
                    item-value="value"
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.countSize"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.phc`]="{ item }">
                  <v-text-field  width="120px"
                    class="ml-1 remove-padding"
                    :disabled="!item.productId"
                    dense
                    outlined
                    hide-details
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.phc"></v-text-field>
                </template>

                <template v-slot:[`item.grade`]="{ item }">
                  <v-autocomplete
                    width="120px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('grade', item)"
                    item-text="value"
                    item-value="value"
                    clearable
                    v-model="item.grade"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.mark`]="{ item }">
                  <v-autocomplete
                    width="120px"
                    :disabled="!item.productId"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('mark', item)"
                    item-text="value"
                    item-value="value"
                    @change="saveLineItem(item)"
                    clearable
                    v-model="item.mark"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.inventoryCode`]="{ item }">
                  <v-autocomplete
                    width="120px"
                    class="ml-1"
                    :disabled="!item.productId"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('inventoryCode', item)"
                    item-text="value"
                    item-value="value"
                    @change="saveLineItem(item)"
                    clearable
                    v-model="item.inventoryCode"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.palletType`]="{ item }">
                  <v-autocomplete
                    width="120px"
                    class="ml-1"
                    :disabled="!item.productId"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('palletType', item)"
                    item-text="name"
                    item-value="value"
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.palletType"
                  ></v-autocomplete>
                </template>

                <template v-slot:footer>
                  <v-list-item
                    @click="addLineItem()"
                    style="background: var(--v-modal-base) !important"
                  >
                    <v-list-item-content class="text-center">
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-data-table>
           
              </v-list>-->
            </v-col>
          </v-row>
        </v-list> 
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="partyModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage {{ partyType }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(partyModal = false), (searchOrganisation = null)"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchOrganisation"
            clearable
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="organisation in filterCustomerOrganisations"
              :key="organisation.id"
              @click="setParty(organisation.relatedOrganisation)"
            >
              <v-list-item-action>
                <v-avatar
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary">
                  <h3 style="color: white">
                    {{ organisation.relatedOrganisation.name.charAt(0) }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="incoTermModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Inco Term </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="incoTermModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="code in incoTerms"
              :key="code.id"
              @click="setIncoTerm(code)"
              style="height: 45px"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ code.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ code.abbreviation }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Select shipment profile -->
    <v-dialog
      v-model="shipmentProfileDialog"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="settingShipmentProfile">
        <v-card-title>
          Search Shipment Profiles
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileDialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" sm="4">
              <v-text-field
                placeholder="Search"
                autofocus
                prepend-inner-icon="search"
                v-model="searchProfiles"
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="profile-info"
            style="cursor: pointer; font-size: 12px"
            :items="filteredProfiles"
            dense
            :loading="loadingShipmentProfiles"
            :headers="shipmentProfileHeaders"
            fixed-header
            height="70vh"
            @click:row="setShipmentProfile"
          >
            <template v-slot:[`item.systemReference`]="{ item }">
              <b>{{ item.systemReference }}</b>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="item.status == 'Draft'"
                color="blue"
                class="white--text"
                small
                >Draft</v-chip
              >
              <v-chip
                v-else-if="item.status == 'Approved'"
                color="success"
                class="white--text"
                small
                >Approved</v-chip
              >
            </template>
            <template v-slot:[`item.shipper.name`]="{ item }">
              <span v-if="item.shipper">{{ item.shipper.name }}</span>
              <span>
                <span v-if="item.onBehalfShipper">
                  <br />
                  On behalf of {{ item.onBehalfShipper.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.forwarder.name`]="{ item }">
              <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
              <span>
                <span v-if="item.onBehalfForwarder">
                  <br />
                  On behalf of {{ item.onBehalfForwarder.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.consignee.name`]="{ item }">
              <span v-if="item.consignee">{{ item.consignee.name }}</span>
              <span>
                <span v-if="item.onBehalfConsignee">
                  <br />
                  On behalf of {{ item.onBehalfConsignee.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.buyer.name`]="{ item }">
              <span v-if="item.buyer">{{ item.buyer.name }}</span>
              <span>
                <span v-if="item.onBehalfBuyer">
                  <br />
                  On behalf of {{ item.onBehalfBuyer.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.loadingPorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allOriginPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'loading'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.dischargePorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allDestinationPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'discharge'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.originCountry.name`]="{ item }">
              <v-tooltip top v-if="item.originCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="item.originCountry && item.originCountry.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.originCountry.name
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.documentationPOD`]="{ item }">
              <template v-if="item.consigneeProfilePorts.length > 0">
                {{ item.consigneeProfilePorts[0].name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <template v-slot:[`item.destinationCountry.name`]="{ item }">
              <v-tooltip top v-if="item.destinationCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="
                        item.destinationCountry && item.destinationCountry.iso2
                      "
                      :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.destinationCountry.name
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.incoTerm`]="{ item }">
              <v-chip
                small
                outlined
                class="mt-1"
                v-for="term in item.consigneeProfileIncoTerms"
                :key="term.id"
                >{{ term.incoTerm }}</v-chip
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shipmentProfileConfig"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Profile Configuration </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileConfig = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="order.consigneeProfile">
          <v-list dense>
            <v-card
            class="my-2 py-2"
            style="background-color: var(--v-greyRaised-base) !important"

            v-for="term in order.consigneeProfile.consigneeProfileIncoTerms" :key="term.id"
             :disabled="order.contractPartyId && term.contractPartyId !== order.contractPartyId"
              @click="setDealTerm(term)"
          >
            <v-card-text class="my-0 py-0">
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Details</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.contractParty &&
                            term.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.contractParty &&
                              term.contractParty.logo
                            "
                            :src="term.contractParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.contractParty">
                            {{ term.contractParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.contractParty"
                        >
                          <span
                            v-if="term.contractParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.contractParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.contractParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 30px">
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                        >
                          {{ term.incoTerm }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          INCO Term
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          class="text-wrap"
                          v-if="term.comment"
                        >
                          {{ term.comment }}
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Notes
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Charges</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.originParty &&
                            term.originParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.originParty &&
                              term.originParty.logo
                            "
                            :src="term.originParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.originParty">
                            {{ term.originParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.originParty"
                        >
                          <span
                            v-if="term.originParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.originParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.originParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.originTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Origin Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.freightParty &&
                            term.freightParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.freightParty &&
                              term.freightParty.logo
                            "
                            :src="term.freightParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.freightParty">
                            {{ term.freightParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.freightParty"
                        >
                          <span
                            v-if="term.freightParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.freightParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.freightParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.paymentTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Freight Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.destinationParty &&
                            term.destinationParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.destinationParty &&
                              term.destinationParty.logo
                            "
                            :src="term.destinationParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.destinationParty">
                            {{ term.destinationParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.destinationParty"
                        >
                          <span
                            v-if="term.destinationParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.destinationParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.destinationParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.destinationTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="countryModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Select {{ countryType }} Country </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(countryModal = false), (searchCountries = null)"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            autofocus
            dense
            hide-details
            v-model="searchCountries"
            clearable
            placeholder="Search"
            prepend-inner-icon="search"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="country in filterCountries"
              :key="country.id"
              @click="setCountry(country)"
            >
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No countries found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shippingLineModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingCarriers">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Carrier</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shippingLineModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            dense
            outlined
            rounded
            prepend-inner-icon="search"
            autofocus
            placeholder="Search"
            clearable
            v-model="searchShippingLine"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="carrier in filterShippingLines"
              :key="carrier.id"
              @click="setShippingLine(carrier)"
            >
              <v-list-item-action>
                <v-avatar v-if="carrier.logo" color="white">
                  <v-img :src="carrier.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary">
                  <h3>{{ carrier.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ carrier.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POL Modal -->
    <v-dialog
      v-model="polModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POL
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="polModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="order.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchOrigin"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-else
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              order.consigneeProfile && !order.consigneeProfile.allOriginPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in loadingPorts"
                :key="port.id"
                @click="setPort('portOfLoad', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == order.portOfLoadValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="order.consigneeProfile">
            <v-progress-linear
              v-if="loadingOriginPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterOriginPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterOriginPort"
                    :key="originPortKey"
                    height="500"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Origin')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'UNMARK', 'Origin')"
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterOriginPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="250"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POD Modal -->
    <v-dialog
      v-model="podModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POD
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="podModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="order.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchDestination"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            v-else
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              order.consigneeProfile &&
              !order.consigneeProfile.allDestinationPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in dischargePorts"
                :key="port.id"
                @click="setPort('portOfDischarge', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == order.portOfDischargeValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="order.consigneeProfile">
            <v-progress-linear
              v-if="loadingDestinationPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterDestinationPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterDestinationPort"
                    :key="destinationPortKey"
                    height="500"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Destination')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="
                              favouritePort(item, 'UNMARK', 'Destination')
                            "
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterDestinationPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="250"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Final Destination Modal -->
    <v-dialog
      v-model="fdModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">location_on</v-icon> Manage
            Final Destination
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(fdModal = false), (portSearch = null)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="loadingPortSearch"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="portResults.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="portResults"
                  height="250"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item
                      :key="item.id"
                      @click="setPort('finalDestination', item)"
                    >
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 12px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleModal"
      persistent
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">directions_boat</v-icon>
            Search Sailing Schedules
          </v-toolbar-title>
          <v-chip
            v-if="order.portOfLoadValue"
            small
            outlined
            style="border: none"
            class="ml-2"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="order.portOfLoadValue"
                :src="`https://cdn.loglive.io/flags/4x3/${order.portOfLoadValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ order.portOfLoadValue }}
          </v-chip>

          <v-icon class="mx-3" color="grey" small>east</v-icon>
          <v-chip
            v-if="order.portOfDischargeValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="order.portOfDischargeValue"
                :src="`https://cdn.loglive.io/flags/4x3/${order.portOfDischargeValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ order.portOfDischargeValue }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn text @click="sailingScheduleModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <ScheduleModal
            :portOfLoadValue="order.portOfLoadValue"
            :finalDestinationValue="order.portOfDischargeValue"
            :shippingLines="shippingLines"
            @select="selectSchedule"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleError"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text class="text-left mt-3 pt-5">
          <p style="font-size: 16px">
            Please select make sure to select the following before continuing:
          </p>
          <ul>
            <li v-if="!order.portOfLoadValue">Port of Load (POL)</li>
            <li v-if="!order.portOfDischargeValue">Port of Discharge (POD)</li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              class="mx-2"
              text
              small
              @click="sailingScheduleError = false"
              >Okay</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="cargoModal"
      width="900px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        
        <v-card-text>
        <ProductSpecification :cargoItem="cargoItem" @close="cargoModal=false,cargoItem = {}" @save="saveCargoItem"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="contractModal"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Contract </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="contractModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text :key="contractKey">
          <v-row>
            <v-col cols="12">
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <v-select
                  outlined
                  dense
                  label="Contract Owner"
                  :items="chargeOptions"
                  v-model="order.contractOwner"
                  item-text="name"
                  item-value="value"
                  style="text-transform: capitalize"
                >
                </v-select>
                <v-text-field
                  label="Contract Number"
                  outlined
                  dense
                  clearable
                  v-model="order.contractNumber"
                ></v-text-field>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="regimeCodeModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card :loading="regimeCodeLoading">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Regime Code </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="regimeCodeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prpend-inner-icon="search"
            v-model="regimeCodeSearch"
            outlined
            dense
            clearable
          ></v-text-field>
          <v-virtual-scroll
            v-if="filterRegimeCodes.length > 0"
            class="my-0 py-0"
            :bench="0"
            :items="filterRegimeCodes"
            height="500"
            item-height="85"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                @click="setRegimeCode(item)"
                style="border-bottom: 0.5px solid grey"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.code }}
                    <v-chip class="ml-2" outlined small v-if="item.steri">
                      <v-icon left color="blue" small>ac_unit</v-icon>Steri Code
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon class="mr-2" color="grey" small>thermostat</v-icon
                    >Set Point: {{ item.setPointTemp }}°C
                    <v-icon class="mx-2" color="red" small>thermostat</v-icon
                    >Max: {{ item.maxProductTemp }}°C
                    <v-icon class="mx-2" color="blue" small>thermostat</v-icon
                    >Min {{ item.minProductTemp }}°C
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.recipeDescription }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterRegimeCodes.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No regime codes found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmOrderItemQuantity" width="400px" persistent>
      <v-card v-if="orderItem">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="confirmSelectedPointModal = false">X</v-btn> -->
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            You have set the container quantity to <b>{{orderItem.quantity}}</b>. Are you sure you would like to continue?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmOrderItemQuantity = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="orderItem.quantityConfirmed=true, confirmOrderItemQuantity=false, saveOrderitem()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="errorSnackbar" :timeout="2000" color="red">Quantity of items must equal 100%</v-snackbar>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import getSymbolFromCurrency from "currency-symbol-map";
import * as moment from "moment";
import * as CurrencyCodes from "currency-codes";
import OrderItemDetail from "./components/OrderItemDetail.vue";
import ProductSpecification from './ProductSpecification.vue'
import ScheduleModal from "../Bookings/Dialogs/SailingSchedule.vue";

export default {
  props: ["order", "organisations", "loadingRelated"],
  components: {
    OrderItemDetail,
    ProductSpecification,
    ScheduleModal,
  },
  data: () => ({
    factoredRate: 1,
    factoredCurrency: 'USD',
    currentExchangeRate: 1,
    applyFactoredRate: false,
    createFactoredRate: false,
    loadingCurrency: false,
    allOriginPorts: [],
    currencies: CurrencyCodes.codes(),
    allDestinationPorts: [],
    cargoModal: false,
    cargoItem: {},
    cargoHeaders: [
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
      
      {
        text: `Type`,
        value: "quantityType",
        align: "center",
      },
      {
        text: `Quantity`,
        value: "quantity",
        align: "center",
      },
      {
        text: "Product",
        value: "product",
        align: "center",
      },
      {
        text: "Variety",
        value: "varietyProduct",
        align: "center",
      },
      {
        text: "Class/Grade",
        value: "grade",
        align: "center",
      },
      {
        text: "Brand/Mark",
        value: "mark",
        align: "center",
      },
      {
        text: "Pack",
        value: "packCode",
        align: "center",
      },
     
      { text: "Count/Size", align: "center", value: "countSize" },
      { text: "PHC", align: "center", value: "phc" },
      
      {
        text: "Inventory Code",
        value: "inventoryCode",
        align: "center",
      },
      {
        text: "Pallet Type",
        value: "palletType",
        align: "center",
      },
    ],
    chargeOptions: [
      { name: "Buyer", value: "buyer" },
      { name: "Forwarder", value: "forwarder" },
      { name: "Shipper", value: "shipper" },
      { name: "Consignee", value: "consignee" },
      { name: "1st Notify Party", value: "firstNotify" },
      { name: "2nd Notify Party", value: "secondNotify" },
      { name: "Customer", value: "customer" },
    ],
    confirmOrderItemQuantity: false,
    containerTypes: [],
    contractKey: 200,
    contractModal: false,
    countries: [],
    countryModal: false,
    countryType: null,
    customerSearchModal: false,
    errorSnackbar: false,
    incoTermModal: false,
    incoTerms: [],
    destinationPortKey: 400,
    originPortKey: 300,
    loadingCarriers: false,
    loadingPortSearch: false,
    loadingProduct: false,
    loadingShipmentProfiles: false,
    polModal: false,
    portResults: [],
    portSearch: null,
    portSearchQuery: undefined,
    portSearchTimeout: undefined,
    podModal: false,
    fdModal: false,
    loadingOriginPorts: false,
    loadingDestinationPorts: false,
    orderFinancialSetting: {
      type: 'FIXED',
      incoTerm: 'FOB',
      baseCurrency: 'USD'
    },
    orderItem: {},
    orderItemModal: false,
    orderSettings: [],
    orderStatus: [
    { name: "Planned", icon: "schedule", color: "#F57C00" },
    { name: "In Progress", icon: "pending", color: "blue" },
    { name: "Allocated", icon: "fact_check", color: "cyan" },
    { name: "Confirmed", icon: "verified", color: "teal" },
    { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      // { name: "Cancelled", icon: "cancel", color: "#F44336" }
    ],
    partyModal: false,
    partyType: null,
    products: {
      data: [],
    },
    productKey: 500,
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      // { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    profileKey: 100,
    regimeCodeModal: false,
    regimeCodeLoading: false,
    regimeCodeSearch: null,
    regimeCodes: [],
    relatedLoading: false,
    sailingScheduleModal: false,
    sailingScheduleError: false,
    savingOrder: false,
    searchCountries: null,
    searchCustomer: null,
    searchDestination: null,
    searchOrganisation: null,
    searchOrigin: null,
    searchProfiles: null,
    searchShippingLine: null,
    settingShipmentProfile: false,
    shippingLineModal: false,
    shippingLines: [],
    shipmentProfileConfig: false,
    shipmentProfileHeaders: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    shipmentProfiles: {
      data: [],
      total: 0,
    },
    shipmentProfileDialog: false,
    tradedProducts: [],
    tradedVariations: [],
    vesselLoading: false,
    vesselQuery: undefined,
    vesselTimeout: undefined,
    vessels: [],
  }),
  watch: {
    'order.consigneeProfile': {
      immediate: true,
      handler(val){
        if(val){
          this.setShipmentProfile(val)
        }
      }
    },
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: "port",
      };
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 750);
    },
    async searchVessel(val) {
      if (this.vesselLoading && this.vesselTimeout) {
        clearTimeout(this.vesselTimeout);
      }
      if (this.vesselLoading && this.vesselQuery) {
        this.vesselQuery.abort();
      }
      this.vesselLoading = true;
      this.vesselTimeout = setTimeout(async () => {
        this.vesselQuery = new AbortController();
        const signal = this.vesselQuery.signal;
        this.vessels = await this.$API.searchVessels({
          signal,
          params: {
            search: val,
          },
        });
        this.vesselLoading = false;
      }, 750);
    },
    // 'createFactoredRate': {
    //   immediate: true,
    //   handler(val) {
    //     if(val) {
    //       console.log(this.order)
    //       console.log(this.orderFinancialSetting)
    //     }
    //   }
    // },
    'factoredCurrency': {
      immediate: true,
      handler(val) {
        if(val) {
          this.getCurrentRate(this.orderFinancialSetting.baseCurrency, this.factoredCurrency)
        }
      }
    }
  },
  computed: {
    validFactoredRate() {
      if(typeof this.factoredRate === 'number') {
        if(this.factoredRate > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    dischargePorts() {
      let result = this.order.consigneeProfile && this.order.consigneeProfile.consigneeProfilePorts
        ? this.order.consigneeProfile.consigneeProfilePorts.filter(
            (x) => x.type == "discharge"
          )
        : [];
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase())) ||
            (x.code &&
              x.code
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    loadingPorts() {
      let result = this.order.consigneeProfile
        ? this.order.consigneeProfile.consigneeProfilePorts.filter(
            (x) => x.type == "loading"
          )
        : [];
      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name.toLowerCase().includes(this.searchOrigin.toLowerCase())) ||
            (x.code &&
              x.code.toLowerCase().includes(this.searchOrigin.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    filterCountries() {
      let result = this.countries;
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
    filterDestinationPort() {
      let result = this.allDestinationPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase()) ||
            x.locode
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase())
        );
      }
      return result;
    },
    filterOriginPort() {
      let result = this.allOriginPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });

      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchOrigin.toLowerCase()) ||
            x.locode.toLowerCase().includes(this.searchOrigin.toLowerCase())
        );
      }
      return result;
    },
    filterProducts() {
      let result = this.products.data;
      result = result.filter((x) => x.type == "Product");
      return result;
    },
    filterVarieties() {
      let result = this.products.data;
      if (this.cargoItem && this.cargoItem.productId) {
        result = result.filter(
          (x) =>
            x.type == "Variety" && x.parentProductId == this.cargoItem.productId
        );
      } else {
        result = [];
      }
      return result;
    },
    filterCustomerOrganisations() {
      let result = this.organisations.data;
      if (this.order.orderCustomer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.order.orderCustomerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.order.orderCustomer,
            relatedOrganisationId: this.order.orderCustomerId,
          });
        }
      }
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result.sort((a, b) =>
        a.relatedOrganisation.name > b.relatedOrganisation.name
          ? 1
          : b.relatedOrganisation.name > a.relatedOrganisation.name
          ? -1
          : 0
      );
      return result;
    },
    filterOrganisations() {
      let result = this.organisations.data ?? [];
      result = result.filter((x) => x.isActive && x.isCustomer);
      let unique = [...new Set(result.map((x) => x.relatedOrganisationId))];
      unique = unique.map((x) =>
        result.find((y) => y.relatedOrganisationId == x)
      );
      let finalResult = [];
      for (let i = 0; i < unique.length; i++) {
        let find = result.find(
          (x) => x.relatedOrganisationId == unique[i].relatedOrganisationId
        );
        if (find) {
          finalResult.push(find);
        }
      }
      if (this.searchCustomer) {
        finalResult = finalResult.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchCustomer.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase()))
        );
      }
      return finalResult;
    },
    filteredProfiles() {
      let result = this.shipmentProfiles.data.filter(
        (x) => x.status == "Approved"
      );
      if (this.searchProfiles) {
        result = result.filter(
          (x) =>
            (x.systemReference &&
              x.systemReference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.reference &&
              x.reference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.forwarder &&
              x.forwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfForwarder &&
              x.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfShipper &&
              x.onBehalfShipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfConsignee &&
              x.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consigneeProfileIncoTerms &&
              x.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.searchProfiles.toLowerCase()))
        );
      }
      return result;
    },
    filterShippingLines() {
      let result = this.shippingLines;
      if (this.searchShippingLine) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase())) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase()))
        );
      }
      return result;
    },
    filterRegimeCodes() {
      let result = this.regimeCodes;
      if (this.regimeCodeSearch) {
        result = result.filter((x) =>
          x.code.toLowerCase().includes(this.regimeCodeSearch.toLowerCase())
        );
      }
      return result;
    },
    weekNumbers(){
      let result = []
      for(let i = 1; i <= 52; i++){
        result.push(i)
      }
      return result
    }
  },
  async created() {
    this.getTradedProducts()
    this.loadProducts();

    this.incoTerms = await this.$API.getIncoTerms();
    this.loadCountries();
    this.getCarrierList();
  },
  methods: {
    addCargoItem(
      item = {
        index: this.orderItem.orderCargoItems.length,
        
        tempId: new Date().getTime(),
      }
    ) {
      this.cargoItem = item;
      if (
        !this.cargoItem.quantity &&
        this.orderItem.orderCargoItems.length == 0
      ) {
        this.cargoItem.quantity = 100;
      } else {
        this.cargoItem.quantity = 0
      }
      this.cargoModal = true;
    },
    addLineItem(containerIndex = 1) {
      this.orderItem.orderCargoItems.push({
        quantityType: 'CT',
        tempId: new Date().getTime(),
        varietyId: null,
        grade: null,
        puc: null,
        countSize: null,
        packCode: null,
        mark: null,
        inventoryCode: null,
        cartons: 0,
        pallets: 0,
        container: 0,
        containerIndex,
      });
    },
    addOrderItem(
      item = {
        quantity: 1,
        bookingCargoItems: [],
        containerTypeCode: "45R1",
        index: this.order.bookingContainerItems.length,
        baseUnit: 'CT',
        containerised: true,
        palletised: true,
        orderCargoItems: [],
        tempId: new Date().getTime(),
      }
    ) {
      if(localStorage.orderSettings){
        let data = JSON.parse(localStorage.orderSettings);
        item.palletised = data.palletised;
        item.containerised = data.containerised;
        item.baseUnit = data.baseUnit;
        item.containerTypeCode = data.containerTypeCode;
        item.unitPerPallet = data.unitPerPallet;
        item.palletPerContainer = data.palletPerContainer;
        item.unitPerContainer = data.unitPerContainer;
      } else {
        item.palletised = true;
        item.containerised = true;
        item.baseUnit = 'CT';
        item.containerTypeCode = '45R1';
        item.unitPerPallet = 80;
        item.palletPerContainer = 20;
        item.unitPerContainer = 1600;
      }
      this.orderItem = item;
      this.orderItemModal = true;
    },
    async deleteItem(index, item) {
      if (item.tempId) {
        let find = this.orderItem.orderCargoItems.findIndex(
          (x) => x.tempId == item.tempId
        );
        this.orderItem.orderCargoItems.splice(find, 1);
      } else {
        let find = this.orderItem.orderCargoItems.findIndex((x) => x.id == item.id);
        this.orderItem.orderCargoItems.splice(find, 1);
        await this.$API.updateForecastLineItem({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
      }
    },
    duplicateItem(item) {
      let newItem = { ...item };
      delete newItem.id;
      delete newItem.createdAt;
      delete newItem.updatedAt;
      this.orderItem.orderCargoItems.push({ ...newItem });
    },
    async favouritePort(item, type, arrayType) {
      if (arrayType == "Origin") {
        let index = this.allOriginPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      } else {
        let index = this.allDestinationPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      }
    },
    getSymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },
    filteredValues(type, item={}) {
      if (type == "packCode") {
        return this.tradedVariations.filter((x) => x.packCode && x.organisationProduct.productId == item.productId ).map(x=>({
          name: x.packCode,
          value: x.packCode
        }));
      } else if (type == "countSize") {
        let filtered = this.tradedVariations.filter((x) => x.countSize  && x.organisationProduct.productId == item.productId);
        if(item.packCode){
          filtered = filtered.filter((x) => x.packCode == item.packCode);
        }
        return filtered.map(x=>({
          name: x.countSize,
          value: x.countSize
        }));
      } else if (type == "variety") {
        let filtered = this.products.data.filter(
          (x) => x.parentProductId == item.productId && x.type == "Variety"
        );
        // let filtered = this.products.data.filter((x) => x.productId == item.productId && type == 'Variety');
        return filtered.map(x=>({
          name: x.name,
          id: x.id
        }));
      }  else if(type == 'palletType') {
        return [
          { name: "Standard", value: "S" },
          { name: "Chep", value: "C" },
          { name: "High Cube", value: "H" },
          { name: "Euro", value: "E" },
        ];
      } else {
        return this.orderSettings.filter((x) => x.key === type && x.productId == item.productId);
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date, time) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (time) {
          result += " " + time;
        }
      }
      return result;
    },
    formatBaseUnit(type) {
      let result = this.productQuantityTypes.find((x) => x.value == type);
      return result ? result.text : "";
    },
    async getCarrierList() {
      this.loadingCarriers = true;
      this.shippingLines = await this.$API.getShippingLines();
      this.containerTypes = await this.$API.listContainerTypes();
      this.loadingCarriers = false;
    },
    async getRegimeCodes(modal = false) {
      this.regimeCodeModal = modal;
      this.regimeCodeLoading = true;
      let params = {};
      if (this.order.destinationCountry) {
        params.countryCode = this.order.destinationCountry.iso2;
      }
      this.regimeCodes = await this.$API.getRegimeCodes({
        params: params,
      });
      this.regimeCodeLoading = false;
    },
    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.order.orderCustomerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    async getTradedProducts(){
       this.tradedProducts = await this.$API.getTradedProducts();
       this.tradedVariations = await this.$API.getAllTradeVariations();
      this.orderSettings = await this.$API.getAllOrderSettings();
    },
    async loadCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
      if (
        !this.order.originCountry &&
        this.$store.state.currentOrg.countryCode
      ) {
        let find = this.countries.find(
          (x) => x.iso2 == this.$store.state.currentOrg.countryCode
        );
        if (find) {
          this.order.originCountry = find;
          this.order.originCountryId = find.id;
        }
      }
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      this.loadingProduct = false;
    },
    productSpec(item) {
      let keys = [
        { key: "puc", value: "PUC" },
        { key: "phc", value: "PHC" },
        { key: "packCode", value: "PACK" },
        { key: "grade", value: "Grade" },
        { key: "countSize", value: "Count" },
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        let find = item.orderProductSpecifications ?? []
        find = find.filter(x=>x.key == keys[i].key)
        if (find.length>0) {
          result.push(keys[i].value + ": " + find.map(x=>x.value).join(', '));
        }
      }
      return result.join(" - ");
    },
    openSailingSchedules() {
      if (this.order.portOfLoadValue && this.order.portOfDischargeValue) {
        this.sailingScheduleModal = true;
        // if(this.order.consigneeProfile)
        // this.searchOceanSchedules()
      } else {
        this.sailingScheduleError = true;
      }
    },
    removeCargoItem(item, index) {
      this.orderItem.orderCargoItems.splice(index, 1);
    },
    removeOrderItem() {
      let index = this.order.bookingContainerItems.findIndex(
        (x) => x.tempId == this.orderItem.tempId
      );
      this.order.bookingContainerItems.splice(index, 1);
      this.orderItemModal = false;
      this.orderItem = {};
    },
    removeProfile() {
      this.order.consigneeProfile = null;
      this.order.consigneeProfileId = null;
      this.order.dealTerm = null;
      this.order.dealTermId = null;
      this.profileKey++;
    },
    async setCustomer(organisation) {
      this.order.orderCustomer = organisation.relatedOrganisation;
      this.order.orderCustomerId = organisation.relatedOrganisationId;
      this.order.consigneeProfile = null;
      this.order.consigneeProfileId = null;
      this.order.dealTerm = null;
      this.order.dealTermId = null;
      this.order.incoTerm = null;
      let parties = [
        "buyer",
        "shipper",
        "consignee",
        "forwarder",
        "firstNotify",
        "secondNotify",
        "courierParty",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.order[parties[i]] = null;
        this.order[parties[i] + "Id"] = null;
      }
      for (let i = 0; i < parties.length; i++) {
        if (organisation[parties[i]]) {
          this.order[parties[i]] = organisation.relatedOrganisation;
          this.order[parties[i] + "Id"] = organisation.relatedOrganisationId;
        }
      }
      this.getShipmentProfiles();
      this.customerSearchModal = false;
    },
    saveCargoItem(item) {
      console.log(item)
      let findIndex = this.orderItem.orderCargoItems.findIndex(
        (x) => x.tempId == item.tempId
      );
      if (findIndex > -1) {
        this.orderItem.orderCargoItems[findIndex] = item;
      } else {
        this.orderItem.orderCargoItems.push(item);
      }
      this.cargoModal = false;
      this.cargoItem = {};
    },
    async saveLineItem(item) {
      if (item.id) {
        await this.$API.updateForecastLineItem(item);
      }
    },
    async saveOrder() {
      try {
        this.savingOrder = true;
        if(this.order.orderStatus == 'Planned'){
          this.order.etd = moment().day("Monday").year(this.order.plannedYear).week(this.order.plannedWeek);
        }
        this.order.orderFinancialSetting = this.orderFinancialSetting;
        let result = await this.$API.createOrder(this.order);
        this.$message.success("Successfully created order!");
        this.savingOrder = false;
        this.$emit("close");
        this.$emit("refresh");
        this.order = {};
      } catch (e) {
        this.$message.error("An error occurred when creating the order!");
        this.savingOrder = false;
      }
    },
    saveOrderItem() {
      // let isValid = this.orderItem.orderCargoItems.map(x=>x.quantity).reduce((a,b)=>a+b, 0) == 100
      // if(isValid){
        if(parseInt(this.orderItem.quantity) > 20 && !this.orderItem.quantityConfirmed){
          this.confirmOrderItemQuantity = true
        } else {
         localStorage.setItem('orderSettings', JSON.stringify({
          palletised: this.orderItem.palletised,
          containerised: this.orderItem.containerised,
          baseUnit: this.orderItem.baseUnit,
          containerTypeCode: this.orderItem.containerTypeCode,
          unitPerPallet: this.orderItem.unitPerPallet,
          palletPerContainer: this.orderItem.palletPerContainer,
          unitPerContainer: this.orderItem.unitPerContainer
        }))
        let findIndex = this.order.bookingContainerItems.findIndex(
        (x) => x.tempId == this.orderItem.tempId
      );
      let findType = this.containerTypes.find(
        (x) => x.typeCode == this.orderItem.containerTypeCode
      );
      this.orderItem.containerTypeDescription = findType
        ? findType.shortDescription
        : null;
      this.orderItem.exists = true;
      if (findIndex > -1) {
        this.order.bookingContainerItems[findIndex] = this.orderItem;
      } else {
        this.order.bookingContainerItems.push(this.orderItem);
      }
      this.orderItemModal = false;
      this.orderItem = {};
      // } else {
      //   this.errorSnackbar = true
      // }
      }
    },
    setCountry(country) {
      if (this.countryType == "Origin") {
        this.order.originCountry = country;
        this.order.originCountryId = country.id;
      } else if (this.countryType == "Destination") {
        this.order.destinationCountry = country;
        this.order.destinationCountryId = country.id;
      }
      this.countryModal = false;
      this.searchCountries = null;
    },
    async setDealTerm(term) {
      this.order.dealTermId = term.id;
      this.order.dealTerm = term;
      this.order.incoTerm = term.incoTerm;
      this.order.freightTerm = term.paymentTerm;
      if(!this.order.contractPartyId){
        this.order.contractPartyId = term.contractPartyId
        this.order.contractParty = term.contractParty
      }
      if(term.contractPartyId || this.order[term.contractOwner]){
        this.order.contractParty = term.contractParty ?? this.order[term.contractOwner]
        this.order.contractPartyId = term.contractPartyId ?? this.order[term.contractOwner + "Id"]
      }
      this.shipmentProfileConfig = false;
    },
    setIncoTerm(term) {
      this.order.incoTerm = term.abbreviation;
      this.incoTermModal = false;
    },
    setParty(organisation) {
      switch (this.partyType) {
        case "Shipper":
          this.order.shipper = organisation;
          this.order.shipperId = organisation.id;
          break;
        case "Consignee":
          this.order.consignee = organisation;
          this.order.consigneeId = organisation.id;
          break;
        case "Forwarder":
          this.order.forwarder = organisation;
          this.order.forwarderId = organisation.id;
          break;
        case "Buyer":
          this.order.buyer = organisation;
          this.order.buyerId = organisation.id;
          break;
        case "First Notify":
          this.order.firstNotify = organisation;
          this.order.firstNotifyId = organisation.id;
          break;
        case "Second Notify":
          this.order.secondNotify = organisation;
          this.order.secondNotifyId = organisation.id;
          break;
        case "Courier Party":
          this.order.courierParty = organisation;
          this.order.courierPartyId = organisation.id;
          break;
      }

      this.partyModal = false;
    },
    setPort(type, item) {
      if (type == "portOfLoad") {
        this.order.portOfLoadValue = item.locode ?? item.code;
        this.order.portOfLoadCity = item.name;
        if (!this.order.originCountry) {
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.order.portOfLoadValue.substring(0, 2)
          );
          if (findCountry) {
            this.order.originCountry = findCountry;
            this.order.originCountryId = findCountry.id;
          }
        }
      } else if (type == "portOfDischarge") {
        this.order.portOfDischargeValue = item.locode ?? item.code;
        this.order.portOfDischargeCity = item.name;
        if (!this.order.finalDestinationValue) {
          this.order.finalDestinationValue = item.locode ?? item.code;
          this.order.finalDestinationCity = item.name;
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.order.finalDestinationValue.substring(0, 2)
          );
          if (findCountry) {
            this.order.destinationCountry = findCountry;
            this.order.destinationCountryId = findCountry.id;
          }
        }
      } else if (type == "finalDestination") {
        this.order.finalDestinationValue = item.locode ?? item.code;
        this.order.finalDestinationCity = item.name;
        let findCountry = this.countries.find(
          (x) => x.iso2 == this.order.finalDestinationValue.substring(0, 2)
        );
        if (findCountry) {
          this.order.destinationCountry = findCountry;
          this.order.destinationCountryId = findCountry.id;
        }
        if (!this.order.portOfDischargeValue) {
          this.order.portOfDischargeValue = item.locode ?? item.code;
          this.order.portOfDischargeCity = item.name;
        }
      }
      this.portSearch = null;
      this.polModal = false;
      this.podModal = false;
      this.fdModal = false;
    },
   
    async setRegimeCode(regime) {
      this.order.regimeCode = regime.code;
      this.order.regime = regime;
      this.order.regimeId = regime.id;
      this.regimeCodeModal = false;
    },
    selectSchedule(schedule) {
      this.order.carrier = schedule.carrier;
      this.order.vessel = schedule.vessel;
      this.order.vesselId = schedule.vesselId;
      this.order.voyage = schedule.voyage;
      this.order.sailingSchedule = schedule;
      this.order.sailingScheduleId = schedule.id;
      this.order.carrierScac = schedule.scac;
      this.order.serviceName = schedule.serviceName;
      this.order.eta = schedule.eta;
      this.order.etd = schedule.etd;
      this.order.shippingLine = schedule.shippingLine;
      this.order.shippingLineId = schedule.shippingLineId;
      this.order.carrierName = schedule.carrier;
      this.order.mainCarriageVesselName = schedule.vesselDescription;
      this.order.mainCarriageConveyanceNumber = schedule.voyage;
      this.order.stackDate = schedule.stackDate;
      this.order.stackDateId = schedule.stackDateId;
      this.sailingScheduleModal = false;
      this.$emit("bookingChange");
    },
    setShippingLine(item) {
      this.order.shippingLine = item;
      this.order.shippingLineId = item.id;
      this.booking.carrier = item;
      this.booking.carrierId = item.id;
      this.shippingLineModal = false;
    },
    statusColor(status) {
      let color = status ? this.orderStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      ):null;
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.orderStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
    async setShipmentProfile(profile) {
      this.settingShipmentProfile = true;
      this.order.dealTerm = null;
      this.order.dealTermId = null;
      this.order.incoTerm = null;
      this.order.portOfLoadValue = null;
      this.order.portOfLoadCity = null;
      this.order.portOfDischargeValue = null;
      this.order.portOfDischargeCity = null;
      this.order.finalDestinationValue = null;
      this.order.finalDestinationCity = null;
      this.order.originCountry = null;
      this.order.originCountryId = null;
      if (
        !this.order.originCountry &&
        this.$store.state.currentOrg.countryCode
      ) {
        let find = this.countries.find(
          (x) => x.iso2 == this.$store.state.currentOrg.countryCode
        );
        if (find) {
          this.order.originCountry = find;
          this.order.originCountryId = find.id;
        }
      }
      this.order.destinationCountry = null;
      this.order.destinationCountryId = null;
      let profileDetail = await this.$API.getConsigneeProfile(profile.id);
      this.profileDetail = profileDetail;
      if (profileDetail.bookingContainerItems.length > 0) {
        this.containerDialog = true;
        this.profileDetailKey++;
      }
      this.order.consigneeProfile = profile;
      this.order.consigneeProfileId = profile.id;
      let parties = [
        "shipper",
        "consignee",
        "forwarder",
        "buyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
        "onBehalfShipper",
        "onBehalfConsignee",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.order[parties[i]] = profile[parties[i]];
        this.order[parties[i] + "Id"] = profile[parties[i] + "Id"];
      }
      this.order.type = profileDetail.type;
      this.order.movementType = profileDetail.movementType;
      this.order.serviceType = profileDetail.serviceType;
      this.order.modeOfTransport = profileDetail.modeOfTransport;
      this.order.originCountry = profile.originCountry;
      this.order.originCountryId = profile.originCountryId;
      this.order.destinationCountry = profile.destinationCountry;
      this.order.destinationCountryId = profile.destinationCountryId;
      if (!this.order.finalDestinationValue) {
        this.order.finalDestinationValue = profileDetail.finalDestinationValue;
        this.order.finalDestinationCity = profileDetail.finalDestinationCity;
      }
      this.order.regimeCode = profileDetail.regimeCode ? profileDetail.regimeCode : this.order.regimeCode;
      this.order.regime = profileDetail.regime ? profileDetail.regime : this.order.regime;
      this.order.regimeId = profileDetail.regimeId ? profileDetail.regimeId : this.order.regimeId;
      this.order.caProduct = profileDetail.caProduct;
      this.order.isPrivateHaulage = profileDetail.isPrivateHaulage;
      this.order.isRailage = profileDetail.isRailage;
      this.order.isCarrierHaulage = profileDetail.isCarrierHaulage;
      if (profileDetail.transportCoordinator) {
        this.order.transportCoordinatorId =
          profileDetail.transportCoordinatorId;
        this.order.transportCoordinator = profileDetail.transportCoordinator;
      }
      if (profileDetail.bookingContainerItems.length > 0) {
        if (!this.order.bookingContainerItems) {
          this.order.bookingContainerItems = [];
          this.order.bookingContainerItems =
            profileDetail.bookingContainerItems;
        }

        this.order.bookingContainerItems.forEach((x) => {
          delete x.id;
          delete x.consigneeProfileId;
          delete x.createdAt;
          delete x.updatedAt;
          x.bookingCargoItems.forEach((y) => {
            delete y.id;
            delete y.bookingContainerItemId;
            delete y.createdAt;
            delete y.updatedAt;
          });
          x.containerMilestones
            ? x.containerMilestones.forEach((y) => {
                delete y.id;
                delete y.bookingContainerItemId;
                delete y.date;
                delete y.createdAt;
                delete y.updatedAt;
              })
            : [];
        });
      }
      if (!profile.allDestinationPorts && profile.consigneeProfilePorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "discharge"
        );
        if (findDest.length == 1) {
          this.setPort("portOfDischarge", {
            locode: findDest[0].code,
            name: findDest[0].name,
          });
        }
      }

      if (!profile.allOriginPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "origin"
        );
        if (findDest.length == 1) {
          this.setPort("portOfLoad", {
            locode: findDest[0].code,
            name: findDest[0].name
          });
        }
      }
      if (
        profileDetail.consigneeProfileIncoTerms &&
        profileDetail.consigneeProfileIncoTerms.length == 1
      ) {
        this.setDealTerm(profileDetail.consigneeProfileIncoTerms[0]);
      }
      this.shipmentProfileDialog = false;
      this.contractKey++;
      this.allOriginPorts = await this.$API.getPortsByCountry(
        profile.originCountry.iso2
      );
      this.allDestinationPorts = await this.$API.getPortsByCountry(
        profile.destinationCountry.iso2
      );
      this.settingShipmentProfile = false;
    },
    setProduct(e, data) {
      let product = this.products.data.find((x) => x.id == e);
      data.product = product;
      data.varietyProduct = null;
      data.varietyProductId = null;
      this.productKey++;
      this.saveLineItem(data);
    },
    setVariety(e, data) {
      data.varietyProductId = e.id;
      this.saveLineItem(data);
    },
    applyCurrentRate() {
      this.factoredRate = parseFloat(this.currentExchangeRate)
    },
    async getCurrentRate(currencyFrom, currencyTo) {
      this.loadingCurrency = true
      let rate = `${currencyFrom}/${currencyTo}`
      // let rate = `USD/ZAR`
      // console.log('Getting current rate')
      if(currencyFrom === currencyTo) {
        this.currentExchangeRate = 1
      } else {
        let res = await this.$API.getLatestExchangeRate({rate: rate})
        this.currentExchangeRate = parseFloat(res.rate.toFixed(4))
      }

      // if(this.appliedLiveExchangeRate) {
      //   this.lineItem.exchangeRate = this.currentExchangeRate
      //   this.appliedExchangeRate = this.inverseCurrentRate
      // }
      // this.lineItem.baseCost = parseFloat((this.lineItem.unitCost * this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
      // if(this.lineItem.currency !== this.lineItem.baseCurrency) {
      //       this.revertedSalePrice = parseFloat((this.lineItem.baseSale / this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
      //       // this.revertedSalePrice = parseFloat((this.lineItem.baseSale * this.appliedExchangeRate).toFixed(2))
      // }
      this.loadingCurrency = false
    },
    closeApply() {
      this.applyFactoredRate = false
    }
  },
};
</script>
<style scoped>
.remove-padding /deep/ .v-input__slot { padding: 0; }
</style>